import React from "react"
import Staff from "../about/Staff"

import FeaturedImage from "./FeaturedImage"
import PageHeader from "./PageHeader"

export default function SinglePage({ data }) {
  const { content, title, featuredImage, databaseId } = data.wpPage
  const caption = featuredImage ? featuredImage.node.caption : ""
  return (
    <div className="wp-page  min-h-[70vh] h-full">
      <div className="px-4 lg:px-8 space-y-4  py-4 lg:py-16 max-w-4xl mx-auto">
        <PageHeader text={title} />
        <div className="flex flex-col lg:flex-row gap-8 ">
          {/* Primary column */}
          <div className="flex-1 overflow-hidden space-y-8">
            <div>
              <div
                className="wp-content"
                dangerouslySetInnerHTML={{ __html: content }}
              />
            </div>{" "}
            {featuredImage && <FeaturedImage image={featuredImage} />}
            {/* {caption && (
              <div className="text-sm">
                <div dangerouslySetInnerHTML={{ __html: caption }} />
              </div>
            )} */}
          </div>
        </div>
        <div className="pt-4">
          {databaseId === 50 && (
            <div>
              <div class="embed-container">
                <iframe
                  src="https://www.youtube.com/embed/YAjr3S1ER4g"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
